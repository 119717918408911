import React from 'react';
import styled, { keyframes } from 'styled-components';

import { query } from '@shared/style-helpers';
import { Box } from '@core';

import globe from '@images/deals/globe.svg';

const rotateBackground = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
`;

const BackgroundWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  top: -300px;
  width: 4600px;
  height: 4600px;

  @media ${query.maxTabletLarge} {
    top: -220px;
    width: 3750px;
    height: 3750px;
  }

  @media ${query.maxTabletSmall} {
    top: 0;
    width: 3750px;
    height: 3750px;
  }

  @media ${query.maxMobile} {
    top: 8px;
    width: 4000px;
    height: 4000px;
  }
`;

const BackgroundImage = styled.div`
  width: 100%;
  height: 100%;

  background-image: url(${globe});
  background-repeat: no-repeat;
  background-size: contain;

  @media ${query.maxTabletSmall} {
    transform: rotate(30deg);
  }

  @media ${query.maxTabletLarge} {
    transform: rotate(25deg);
  }

  @media ${query.minDesktop} {
    animation-name: ${rotateBackground};
    animation-duration: 100s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
`;

const DealsBackgroundWrapper = ({ children }) => (
  <Box position="relative">
    <BackgroundWrapper>
      <BackgroundImage />
    </BackgroundWrapper>
    {children}
  </Box>
);

export default DealsBackgroundWrapper;
